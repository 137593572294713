.background-cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide {
    height: calc(100vh - 75px);
    @extend .background-cover;

    &--1 {
        background-image: url('../images/slider/home-slider-3.jpg');
    }

    &--2 {
        background-image: url('../images/slider/home-slider-1.jpg');
    }

    &__content {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding-left: 50px;

        &_title {
            font-size: 60px;
            line-height: 80px;
            font-family: $semibold;
            margin-bottom: $spacer;

            h1 {
                margin-bottom: 0;
            }

        }
    }

}



/* Floating Social Media Bar Style Starts Here */

.fl-fl {
    background: $black;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 7px;
    width: 158px;
    position: fixed;
    right: -126px;
    z-index: 1000;
    display: flex;
    align-items: center;
    transition: all .25s ease;

    &:hover {
        right: 0;
    }

    i {
        color: #fff;
        font-size: 20px;
    }

    a {
        color: #fff;
        text-decoration: none;
        text-align: center;
        vertical-align: top;
        font-size: 12px;
        letter-spacing: 0.1px;
        margin-left: 5px;
    }
}

$top-pos:180px;
$mov-mat : 50px;

.float-position {
    &__1 {
        top: $top-pos;
    }

    &__2 {
        top: $top-pos + $mov-mat;
    }

    &__3 {
        top: $top-pos + $mov-mat*2;
    }

    &__4 {
        top: $top-pos + $mov-mat*3;
    }

    &__5 {
        top: $top-pos + $mov-mat*4;
    }
}


/* Floating Social Media Bar Style Ends Here */