@font-face {
    font-family: 'AvenirNext-Regular';
    src: url('../fonts/AvenirNext-Regular.eot');
    src: url('../fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg'),
        url('../fonts/AvenirNext-Regular.ttf') format('truetype'),
        url('../fonts/AvenirNext-Regular.woff') format('woff'),
        url('../fonts/AvenirNext-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext-Medium';
    src: url('../fonts/AvenirNext-Medium.eot');
    src: url('../fonts/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Medium.svg#AvenirNext-Medium') format('svg'),
        url('../fonts/AvenirNext-Medium.ttf') format('truetype'),
        url('../fonts/AvenirNext-Medium.woff') format('woff'),
        url('../fonts/AvenirNext-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext-DemiBold';
    src: url('../fonts/AvenirNext-DemiBold.eot');
    src: url('../fonts/AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg'),
        url('../fonts/AvenirNext-DemiBold.ttf') format('truetype'),
        url('../fonts/AvenirNext-DemiBold.woff') format('woff'),
        url('../fonts/AvenirNext-DemiBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext-Bold';
    src: url('../fonts/AvenirNext-Bold.eot');
    src: url('../fonts/AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Bold.svg#AvenirNext-Bold') format('svg'),
        url('../fonts/AvenirNext-Bold.ttf') format('truetype'),
        url('../fonts/AvenirNext-Bold.woff') format('woff'),
        url('../fonts/AvenirNext-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.eot");
    src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Flaticon.woff2") format("woff2"),
        url("../fonts/Flaticon.woff") format("woff"),
        url("../fonts/Flaticon.ttf") format("truetype"),
        url("../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
    }
}

.fi:before {
    display: inline-block;
    font-family: "Flaticon";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.flaticon-discount:before {
    content: "\f100";
}

.flaticon-shuttle:before {
    content: "\f101";
}

.flaticon-wrench:before {
    content: "\f102";
}

.flaticon-steering-wheel:before {
    content: "\f103";
}

.flaticon-reward:before {
    content: "\f104";
}

.flaticon-security:before {
    content: "\f105";
}

.flaticon-pin:before {
    content: "\f106";
}

.flaticon-car:before {
    content: "\f107";
}

.flaticon-idea:before {
    content: "\f108";
}

.flaticon-booking:before {
    content: "\f109";
}

.flaticon-steering-wheel-1:before {
    content: "\f10a";
}

$font-Flaticon-discount: "\f100";
$font-Flaticon-shuttle: "\f101";
$font-Flaticon-wrench: "\f102";
$font-Flaticon-steering-wheel: "\f103";
$font-Flaticon-reward: "\f104";
$font-Flaticon-security: "\f105";
$font-Flaticon-pin: "\f106";
$font-Flaticon-car: "\f107";
$font-Flaticon-idea: "\f108";
$font-Flaticon-booking: "\f109";
$font-Flaticon-steering-wheel-1: "\f10a";