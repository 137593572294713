.cars {
    &__block {
        &_img {
            padding-top: 5px;

            img {
                margin: 0 auto;
                max-width: 100%;
                transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
            }
        }

        &_text {
            text-align: center;
            margin-top: 1rem;
            text-transform: uppercase;
            font-family: $semibold;
        }

        &:hover & {
            &_img {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }

    .slick-list {
        @include media-breakpoint-down(md) {
            padding: 0 10%;
        }

    }
}

.feature {
    &__col {
        text-align: center;

        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }

        &_icon {
            font-size: 50px;
        }

        &_title {
            margin-top: 0;
            text-transform: uppercase;
            font-family: $semibold;
        }
    }
}

.two-block {
    &__img {
        border-radius: 16px;
        overflow: hidden;
    }

    &__text {
        margin-top: 1rem;
        text-align: center;
        font-family: $semibold;
    }
}

.no-gutter {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }


    &__2 {
        flex: 0 0 50%;
        max-width: 50%;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &__3 {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.two-column-block {
    &__right {
        position: relative;

        &::before {
            content: "";
            background-color: rgba(0, 0, 0, .5);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}




.process {
    margin-top: 4rem;

    &__wrapper~&__wrapper {
        margin-top: 100px;
        position: relative;

        &::before {
            content: "";
            width: 1px;
            height: 50px;
            background-color: $blue;
            position: absolute;
            top: -70px;
            left: 30px;

            @include media-breakpoint-down(md) {
                left: 50%;
                height: 35px;
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            text-align: center;
        }

        &_icon {
            width: $pro-circle;
            height: $pro-circle;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $blue;
            border-radius: 50%;
            position: relative;
            flex: 0 0 $pro-circle;

            @include media-breakpoint-down(md) {
                margin-bottom: 1rem;
            }

            &::after {
                content: "";
                width: $pro-circle +15px;
                height: $pro-circle +15px;
                position: absolute;
                background-color: rgba($color: $blue, $alpha: 0.25);
                border-radius: 50%;
            }

            svg.icon {
                width: 30px;
                height: 30px;
                fill: #fff;
                position: relative;
                z-index: 9;
            }
        }

        &_content {
            margin-left: 50px;

            @include media-breakpoint-down(md) {
                margin-left: 0px;
            }

            &_title {
                font-family: $semibold;
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
    }
}

.about_features {
    margin-top: 3rem;

    li~li {
        margin-top: 20px;
    }

    li {
        background-color: $grey;
        padding: 10px 20px;
        font-size: 16px;
        font-family: $semibold;

        @include media-breakpoint-down(md) {
            padding: 10px 10px;
            font-size: 14px;
            font-family: $medium;
        }
    }

    svg {
        &.icon {
            width: 20px;
            height: 20px;
            background-size: 20px;
            margin-right: 10px;
        }
    }
}

.about_grphic {
    @include media-breakpoint-down(md) {
        margin-top: 3rem;
    }
}

.feature {
    @include media-breakpoint-down(md) {
        >.row {
            align-items: center;
            justify-content: center;

            .col {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .two-column-block {
        .p-5 {
            padding: 2rem !important;
        }
    }
}

.compare {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    background-color: $grey;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &__bg {
        height: 100%;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
            object-position: left;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.locations {
    &__icon {
        font-size: 24px;
        margin-bottom: 1rem;
    }

    &__city {
        margin-bottom: 1.5rem;

        h4 {
            font-size: 18px;
            font-family: $semibold;
            letter-spacing: 0.5px;
        }
    }

    &__info {
        line-height: 1.88;

        &_address {
            margin-bottom: 0.5rem;
        }

        &_phone {
            margin-bottom: 0.5rem;
            font-family: $semibold;
        }

        &_phone,
        &_email {
            li {
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 25px;

                &::before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    display: block;
                    background-size: 20px;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 0;
                }
            }
        }

        &_phone {
            li {
                &::before {
                    background-image: url('../images/icon-phone.png');
                }
            }
        }

        &_email {
            li {
                &::before {
                    background-image: url('../images/icon-mail.png');

                }
            }
        }
    }
}

.input-field {
    margin-bottom: 0;
}

.reservenow {
    &__info {
        table {
            td {
                width: 50%;
                font-size: 12px;

                span.selected-color {
                    width: 20px;
                    height: 20px;
                    display: block;
                    background-color: $grey;
                    border-radius: 50%;
                    margin-right: 10px;

                    &.red {
                        background-color: $car-red;
                    }
                }
            }
        }
    }
}

.tab-content {
    .offer-card {

        border: 1px solid darken($color: $grey, $amount: 5%);
    }
}

.offer-card {
    margin-bottom: 1.5rem;

    &__content {
        background-color: #fff;
        padding: 20px 15px;

        ul {
            li {
                margin-top: 0.5rem;
                font-size: 0.9rem;
                color: rgba($color: $black, $alpha: 0.7);
            }

            margin-bottom: 2rem;
        }

        &--title {
            h3 {
                font-size: 1.15rem;
                text-transform: uppercase;
                font-family: $semibold;

                span {
                    color: rgba($color: $black, $alpha: 0.6);
                }
            }
        }
    }

    &__image {
        border: 1px solid $grey;
    }

}

.offer-card__popup {
    .offer-card__content {
        background: #fff;
        padding: 2rem 0 0 0;
    }
}

.offer-filter__results {
    display: flex;
    height: 100%;
    align-items: center;
}

.white-popup {
    position: relative;
    background: #FFF;
    padding: 30px;
    width: auto;
    max-width: 600px;
    margin: 20px auto;
    transition: 1s all;
}

.filter-results {
    &__title {
        text-transform: uppercase;
        margin-right: 15px;
        font-family: $semibold;
        font-size: 12px;
    }
}

.off-car-name {
    background: $blue;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
}

.offer-tab {
    .nav-pills {
        max-width: 400px;

        .nav-link {
            text-transform: uppercase;
            font-size: 12px;
            font-family: $semibold;
            border-radius: 0;
            background-color: $grey;

            &.active {
                background-color: $black;
            }
        }
    }

    .tab-content {
        background: $grey;
        padding: 15px 30px;
    }
}