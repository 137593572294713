.overlay__info {
    position: relative;
    z-index: 9;
}

.brand-info {
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
    height: 100%;

    &__theme {
        background-color: $blue;
    }

    &__alt {
        background-color: $black;
    }

    h6 {
        font-size: 1.06rem;
        font-family: $semibold;
        line-height: 1.66;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
        margin-top: 10px;

    }
}

.count {
    display: flex;
    align-items: center;

    &__icon {
        background-image: url(../images/icon-keypad.svg);
        background-size: 35px;
        width: 35px;
        height: 35px;
        display: inline-block;
        background-repeat: no-repeat;
        margin-right: 10px;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
    }

    &__number {
        font-size: 26px;
        font-family: $semibold;
        color: #fff;
    }
}

.cta-block {
    background: $black;
    color: #fff;
    width: 100%;
    padding: 60px;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.tracking {
    &__group {
        position: relative;

        input[type=text]:not(.browser-default) {
            border: 1px solid #9e9e9e;
            padding: 0 1rem;
            margin-bottom: 0;
            box-sizing: border-box;
        }

        &_btn {
            position: absolute;
            height: 3rem;
            border: none;
            right: 0;
            padding: 0 1.5rem;
            font-size: 0.9rem;
            text-transform: uppercase;
            background-color: $black;
            color: #fff;
            font-family: $semibold;
            letter-spacing: 1.5px;
            transition: all 0.2s;

            &:hover {
                color: rgba($color: #fff, $alpha: 0.5);
            }
        }

    }
}

.tracking-history {
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    padding: 1.5rem 2rem;
    border-radius: 0.42rem;

    &__date {
        font-size: 12px;
        font-family: $semibold;
        color: $blue;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    &__conent {
        font-size: 1.05rem;
        font-family: $medium;
        color: rgba($color: $black, $alpha: 0.7);
    }
}

.tracking-history~.tracking-history {
    margin-top: 20px;
}

.select-car-image {
    text-align: center;


    img {
        max-width: 400px;
        margin-bottom: 30px;
    }
}

.creative-section {
    padding: 0 10px;
    margin-top: 100px;

    .row {
        margin-bottom: 0;
    }

    &__content {
        padding: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #fff;
    }
}

.qlty-block {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    border-radius: 0.42rem;
    padding: 2rem 2.25rem;

    @include media-breakpoint-down(md) {
        padding: 1rem;
    }

    height: 100%;

    &__icon {
        svg {
            width: 50px;
            height: 50px;

            @include media-breakpoint-down(md) {
                width: 30px;
                height: 30px;
            }

            fill: rgba($color: $black, $alpha: 0.7);
        }
    }

    &__value {
        margin-top: 0.5rem;
        font-size: 1.5rem !important;

        @include media-breakpoint-down(md) {
            font-size: 1rem !important;
        }

        font-family: $semibold;
    }

    h5 {
        font-family: $medium;
        margin-bottom: 0;
        color: rgba($color: $black, $alpha: 0.5);
        font-size: 1.15rem;

        @include media-breakpoint-down(md) {
            font-size: 0.9rem !important;
        }
    }
}

ul {
    &.dotted {
        li {
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
            position: relative;
            padding-left: 15px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 5px;
                height: 5px;
                background-color: $black;
                border-radius: 50%;
                top: 8px;
            }
        }

    }
}

.car-spec-min {
    &__icon {
        svg {
            width: 50px;
            height: 50px;
        }
    }
}

.time-info {
    color: rgba($color: $black, $alpha: 0.6);
    margin-top: 3rem;
}

.key-features {
    text-align: center;
    background: darken($color: $grey, $amount: 10%);
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    position: relative;

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
    }

    &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: darken($color: $grey, $amount: 10%);
        border-width: 10px;
        margin-left: -10px;
    }

    h5 {
        font-size: 14px;
        font-family: $semibold;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    ul {
        li {
            font-size: 13px;
            color: lighten($color: $black, $amount: 10%);
        }
    }
}

.about-slider {
    height: 515px;

    .carousel {
        height: 850px;
        perspective: 600px;
        transform: translateY(-335px);
    }

    .carousel .carousel-item {
        cursor: grab;
        width: 400px;
    }

    .carousel .carousel-item:active {
        cursor: grabbing;
    }

    .carousel .carousel-item img {
        width: 100%;
    }

    .carousel .carousel-item h3 {
        background-color: #ffffff;
        color: #000000;
        font-size: 2em;
        font-weight: bold;
        margin: -5px 0 0;
        padding: 10px 5px;
        text-align: center;
    }
}