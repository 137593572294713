.c-button {
    @include media-breakpoint-down(md) {
        text-align: center;
    }

    &__theme {
        border: 1px solid $black;
        padding: 0 2.5rem;

        @include media-breakpoint-down(md) {
            padding: 0 10px;
        }

        height: $btn-h;
        border-radius: 0;
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 13px;

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }

        letter-spacing: 1px;
        font-family: $semibold;
        box-shadow: none;

        &:hover {
            border: 1px solid $blue;
            box-shadow: none;
            background-color: transparent;

            .anim {
                animation: slide1 1s ease-in-out infinite;
            }
        }

        &_arrow {
            background-image: url('../images/btn_arrow.svg');
            background-size: 28px;
            width: 28px;
            height: 28px;
            display: inline-block;
            background-repeat: no-repeat;
            margin-left: 15px;


        }

        &.light-btn {
            border-color: #fff;
        }
    }

    &__light {
        border-color: #fff;
        color: #fff;

        &:hover {
            color: rgba($color: #fff, $alpha: 0.7);

            .arrow-white {
                opacity: 0.7;
            }
        }

        .arrow-white {
            background-image: url('../images/btn_arrow_white.svg');
            background-size: 28px;
            width: 28px;
            height: 28px;
            display: inline-block;
            background-repeat: no-repeat;
            margin-left: 15px;


        }
    }
}

.button__arrow {
    position: absolute;
    background-image: url('../images/right_black.svg');
    right: 0;
    top: 20px;
    text-indent: -9999px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.download__btn {
    border-radius: 0;
    padding: 0 1.2rem;
    height: $btn-h;
    text-transform: uppercase;
    font-size: 10px;
    font-family: $semibold;
    border: none;
    letter-spacing: 1px;
    background-color: $black;
    color: #fff;
    display: inline-flex;
    align-items: center;
    transition: all 0.2s;

    &:hover,
    &:focus {
        color: rgba($color: #fff, $alpha: 0.5);
        background-color: $black;
    }

    &:hover & {
        &_icon {
            opacity: 0.5;
        }
    }

    &_icon {
        background-image: url('../images/download.svg');
        background-size: 15px;
        width: 15px;
        height: 15px;
        display: inline-block;
        background-repeat: no-repeat;
        margin-left: 15px;
        transition: opacity 0.5s;

        &.icon-dwn-dark {
            background-image: url('../images/down-arrow-dark.svg');
        }

        &.icon-plus {
            background-image: url('../images/plus.svg');
        }

        &.icon-arrow {
            &__right {
                background-image: url('../images/right.svg');
            }
        }

        &.icon-send {
            background-image: url('../images/mail-outline.svg');
            background-size: 20px;
            width: 20px;
            height: 20px;
        }

        &.icon-track {
            background-image: url('../images/pin.svg');
        }

        &.icon-track-white {
            background-image: url('../images/pin-w.svg');
        }

        &.icon-testdrive {
            background-image: url('../images/steering-wheel.svg');

            &-white {
                background-image: url('../images/steering-wheel-white.svg');
            }
        }

        &.icon-service {
            background-image: url('../images/service.svg');
        }

        &.icon-compare {
            background-image: url('../images/compare.svg');
        }

        &.icon-arrow-right {
            background-image: url('../images/right-arrow.svg');
        }
    }

    &-outline {
        background-color: transparent;
        border: 1px solid $black;
        color: $black;

        &:hover {
            color: rgba($color: $black, $alpha: 0.5);
            background-color: transparent;
        }

    }
}