.primary__title {
    margin-bottom: 2rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

    }
}

.semibold {
    font-family: $semibold;
}

h6 {
    a {
        font-family: $semibold;
    }
}

.heading {
    &__wrap {
        max-width: 600px;

        &_500 {
            max-width: 500px;
        }

        &_800 {
            max-width: 800px;
        }
    }
}

.subtitle {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 0.9rem;
    font-family: $semibold;
    color: rgba($color: $black, $alpha: 0.7);
}