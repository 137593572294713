//Custom variables

// Descriptive color variables
$black: #121212;
$blue:#03448d;
$grey: #e9ecec;
// Functional color variables

// Font stacks
$base:'AvenirNext-Regular';
$medium:'AvenirNext-Medium';
$semibold:'AvenirNext-DemiBold';
$bold:'AvenirNext-Bold';

// Asset paths
// $path-image   :   '../img';
// $path-fonts     :  '../fonts';

$space:100px;
$pro-circle:60px;
$btn-h:40px;

$car-red: #810806;
$car-white: #C2C2C2;
$car-blue:#051D75;
$car-grey:#434343;
$car-brown:#7B5034;
$car-black:#121212;
$car-purple:#76507e;