.slider {
    &__timeline {
        position: relative;
        padding-top: 80px;
        max-width: 800px;
        margin: 0 auto;

        .row {
            margin: 0;
        }

        .col-sm-5 {
            padding: 0;
            background-color: $grey;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
        }

        .col-sm-7 {
            padding: 0;
        }

        h2 {
            font-family: $base;
            color: $blue;
        }

        h3 {
            font-family: $semibold;
            font-size: 18px;
        }

        p {
            margin-bottom: 0;
        }

        .owl-dots {
            width: 100%;
            text-align: center;
            margin-top: 2rem;
            position: absolute;
            top: -120px;

            button {
                position: relative;
                font-weight: 900;

                outline: none;
                box-shadow: none;
                padding: 0 15px !important;

                @include media-breakpoint-down(md) {
                    padding: 0 5px !important;
                }

                span {
                    outline: none;
                    box-shadow: none;
                    font-size: 20px;

                    @include media-breakpoint-down(md) {
                        font-size: 12px;
                    }

                    font-family: $semibold;

                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -.5rem;
                    width: 100%;
                    height: .2rem;
                    background: $black;
                }

                &:first-of-type {
                    &:after {
                        border-top-left-radius: 10rem;
                        border-bottom-left-radius: 10rem;
                    }
                }

                &:nth-last-of-type(1) {
                    &:after {
                        border-top-right-radius: 10rem;
                        border-bottom-right-radius: 10rem;
                    }
                }

                &.active {
                    color: var(--blue);

                    &:after {
                        background: $blue;
                    }
                }
            }
        }
    }
}