.v1 {
    .car-pic {
        height: 320px;
        background-size: 70%;

        @include media-breakpoint-down(sm) {
            height: 160px;
            background-size: 100%;
        }
    }
}