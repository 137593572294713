.video-banner {
    position: relative;
    background-color: black;
    height: 95vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translateX(-50%) translateY(-50%);
    }

    &__overlay {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        z-index: 9;

        h1 {
            color: #fff;
            font-size: 52px;

            @include media-breakpoint-down(sm) {
                font-size: 34px;
            }
        }

        h3 {
            text-align: center;
            color: #fff;
            font-size: 24px;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
            }

            opacity: 0.8;
            line-height: 1.6;
        }
    }

    &::before {
        content: "";
        background-color: rgba($color: $black, $alpha: 0.5);
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        top: 0;
        bottom: 0;
        display: block;
        z-index: 1;
    }
}

ul.slick-dots {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
        padding: 0 2px;

        button {
            border: none;
            text-indent: -9999px;
            background-color: $grey;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            display: block;
            padding: 0;
            margin: 0 3px;
            transition: all 0.4s ease;
        }

        &.slick-active {
            button {
                width: 20px;
                background-color: $blue;
                border-radius: 10px;
            }
        }
    }


}