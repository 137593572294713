.compare-block {
    &__header {
        background-color: $grey;
        padding: 20px;

        h3 {
            margin-bottom: 0;
        }

        &--car {
            text-align: center;
            margin-bottom: 20px;

            img {
                max-width: 80%;
            }
        }

        &--carname {
            text-align: center;
            text-transform: uppercase;
            font-size: 16px;
            font-family: $semibold;

            small {
                opacity: 0.6;
                margin-left: 5px;
            }
        }
    }

    &__footer {
        table {
            margin-bottom: 0;

            td {
                width: 25%;
                font-size: 12px;
                text-align: center;

                &:first-child {
                    text-align: left;
                }
            }
        }

        &--carname {
            text-align: center;
            text-transform: uppercase;
            font-size: 16px;
            font-family: $semibold;

            small {
                opacity: 0.6;
                margin-left: 5px;
            }
        }
    }
}

.add-car {
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle-block {
    border-left: 1px dashed #ccc;
    border-right: 1px dashed #ccc;
}