.object-fit {
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
    }
}

@keyframes slide1 {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(5px, 0);
    }
}

input[type=range]::-webkit-slider-thumb {
    background-color: #03448D;
}

.mark,
mark {
    background-color: rgba($color: #9ff8d0, $alpha: 1);
}