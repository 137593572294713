/* Custom Mixins -------------------- */
.border {
    &__top {
        &_1 {
            border-top: 1px solid rgba($color: $black, $alpha: 0.1);
        }
    }
}

.title {
    &__bold {
        font-family: $bold;
    }

    &__semibolod {
        font-family: $semibold;
    }
}

.font-size {
    &__big {
        font-size: 30px;
    }

    &__small {
        font-size: 1.15rem;
        font-family: $semibold;
    }
}


.spacer {
    &__40 {
        height: 40vh;
    }

    &__50 {
        height: 50vh;
    }
}

.background {
    position: relative;
    min-height: 200px;
    overflow: hidden;

    &__paralax {
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-attachment: scroll;
        background-color: transparent;
        background-image: inherit;
        background-size: cover;
        z-index: 0;
        background-position: 50% 0;
        height: 100%;
    }

    &__image {
        &_1 {
            background-image: url('../images/aboutus_1.jpg');
        }

        &_2 {
            background-image: url('../images/r&d.jpg');
        }
    }
}