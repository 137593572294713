.news {
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }

    &__title {
        margin-top: 1rem;

        &_wrap {
            max-width: 800px;
        }

        h5 {
            line-height: 1.5;
        }
    }




    &__content {
        img {
            max-width: 100%;
        }
    }

    figure {
        margin-bottom: 25px;
    }

}

.news-block {
    &__image {
        overflow: hidden;

        img {
            transition: all cubic-bezier(.4, 0, .2, 1) .4s;
        }
    }


    &:hover & {
        &__image {
            overflow: hidden;

            img {
                transform: scale3d(1.1, 1.1, 1);
            }
        }
    }

}

.about-info-block {
    background-color: #fff;
    margin-bottom: 10px;
    margin-top: 10px;

    .news__details {
        padding: 30px;
    }
}