.my-account {
    h2 {
        font-size: 14px;

        span {
            text-transform: uppercase;
            font-family: $semibold;
        }
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        background-color: #fff;
        color: $black;
        font-family: $semibold;
        box-shadow: rgba(173, 193, 207, 0.25) 0px 2px 10px 0px;
    }

    .nav-pills .nav-link {
        border-radius: 0;
        font-size: 14px;

    }

    .nav-link {
        padding: 12px 15px;
    }

    .tab-content {
        h3 {
            font-size: 18px;
            font-family: $semibold;
            margin-bottom: 5px;
        }

        h6 {
            font-size: 12px;
            color: lighten($color: $black, $amount:30%);
        }
    }
}

.order-status {
    table {
        background-color: #fff;

        td,
        th {
            padding: 15px 10px;
        }

        thead {
            th {
                font-family: $semibold;
                font-size: 14px;
            }
        }

        tbody {
            td {
                font-size: 14px;
            }
        }
    }
}

.form-block {
    background: #fff;
    padding: 30px;
}

.address-table {
    table {
        td {
            width: 15%;

            &.update {
                width: 10%;
            }

            &.status {
                width: 20%;
            }

            &.address-info {
                width: 55%;
            }
        }
    }
}