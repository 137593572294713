@include media-breakpoint-down(md) {
    .c-header {
        .px-3 {
            padding: 0 1rem !important;
        }

        .navbar-collapse {
            margin-top: 1rem;


        }

        nav ul li {
            border: 1px solid $grey;
            border-bottom: 0;

            &:last-child {
                border-bottom: 1px solid $grey;
            }

            .dropdown-toggle::after {
                position: absolute;
                right: 15px;
                top: 50%;
            }
        }

        .navbar-toggler {
            padding: 0 !important;
            width: 25px;
            background-image: url('../images/menu.svg');
            height: 25px;
        }
    }
}

button:focus {
    outline: none;
    background-color: transparent;
    outline: none !important;
}