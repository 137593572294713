/* Base Styles -------------------- */

body {
    font-size: 15px;
    font-family: $base;
    line-height: 1.7;
    overflow-x: hidden;
}

b,
strong {
    font-family: $semibold;
}

.slick-slide {
    outline: none
}

a:focus {
    outline: none
}

blockquote {
    border-left: 4px solid $grey;
    margin-bottom: 25px;
}

a.anim {
    color: $black;
    position: relative;
    text-decoration: none;
    transition: color .4s ease-out;

    &:hover {
        color: $blue;
        right: 0;
        text-decoration: none;

        &:after {
            border-color: $blue;
            right: 0;
            animation: anchor-underline 2s cubic-bezier(0, .5, 0, 1) infinite;
            border-color: $blue;
        }

        &:after {
            border-radius: 1em;
            border-top: .1em solid $blue;
            content: "";
            position: absolute;
            right: 100%;
            bottom: .14em;
            left: 0;
            transition: right .4s cubic-bezier(0, .5, 0, 1),
                border-color .4s ease-out;
        }
    }
}

a {
    color: $black;
    position: relative;
    text-decoration: none;

    &:hover {
        color: $blue;
        text-decoration: none;
    }
}

p {
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0;
    }
}

@keyframes anchor-underline {

    0%,
    10% {
        left: 0;
        right: 100%;
    }

    40%,
    60% {
        left: 0;
        right: 0;
    }

    90%,
    100% {
        left: 100%;
        right: 0;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.bg {
    &__grey {
        background-color: $grey !important;
    }

    &__blue {
        background-color: $blue !important;
    }
}


.video-play-button {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    /* background: #fa183d; */
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
}

.video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #fff;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #fff;
    border-radius: 50%;
    transition: all 200ms;
}

.video-play-button:hover:after {
    background-color: $blue;
}

.video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
}

.video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid $black;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include media-breakpoint-down(md) {
        padding: 2rem 1rem !important;
    }
}

.desktop {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.mobile {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
    }
}

@include media-breakpoint-down(md) {
    .two-block {
        &:nth-of-type(1) {
            margin-top: 3rem;
        }
    }

    .pb-5,
    .py-5 {
        padding-bottom: 3rem !important;
    }

    .pt-5,
    .py-5 {
        padding-top: 3rem !important;
    }
}

@include media-breakpoint-down(md) {
    .mobile {
        &__pt {
            &_0 {
                padding-top: 0 !important;
            }

        }
    }
}

.c-dn {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.overlay {
    position: relative;

    &_50 {
        &::before {
            content: "";
            background-color: rgba($color: $black, $alpha: 0.5);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

.specification {
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    ul {
        &.tabs {
            height: auto;

            @include media-breakpoint-down(md) {
                margin-top: 2rem;
                align-items: center;
            }

            li {
                display: inline-block;
                margin-left: 20px;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    font-size: 12px;
                    padding: 0 10px;
                }

                font-size: 16px;
                font-family: $medium;
                cursor: pointer;

                &.current {
                    color: $blue;
                }
            }
        }
    }

    .tab-content {
        display: none;
        margin-top: 2rem;
    }

    .tab-content.current {
        display: inherit;
    }
}

nav ul a:hover {
    background-color: transparent;

    .cars__block_img img {
        transform: scale(1.05);
    }
}

.dropdown-item {
    padding: 10px 0;
}

.datepicker-modal,
.timepicker-modal {
    height: auto;
}

.timepicker-digital-display,
.datepicker-date-display {
    background-color: $blue;

    .text-primary {
        color: #fff !important;
    }
}

select {
    border-color: rgba($color: $black, $alpha: 0.5);
}

.mfp-3d-unfold {


    .mfp-content {
        perspective: 2000px;
    }

    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform-style: preserve-3d;
        transform: rotateY(-60deg);
    }


    &.mfp-bg {
        opacity: 0;
        transition: all 0.5s;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: rotateY(0deg);
        }

        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {

        .mfp-with-anim {
            transform: rotateY(60deg);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }

    }

}