.footer {
    padding: $space 0 0 0;
    border-top: 1px solid rgba(18, 18, 18, 0.1);

    @include media-breakpoint-down(md) {
        padding: $space/2 0 0 0;
    }

    .row .col {
        min-height: auto;
    }

    &__menu {
        margin-top: $space/2;
        padding-top: $space/2;

        @include media-breakpoint-down(md) {
            margin-top: $space/4;
            padding-top: $space/4;
        }

        border-top: 1px solid rgba($color: $black, $alpha: 0.1);

        h5 {
            font-family: $medium;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        ul {
            @include media-breakpoint-down(md) {
                display: none;
                margin-bottom: $space /4;
            }
        }

        li~li {
            margin-top: $spacer/4;
        }

        li {
            a {
                color: $black;

                &:hover {
                    color: $blue;
                    text-decoration: none;
                }
            }
        }

        .row {
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }
    }

    &__copyright {
        margin-top: $space/2.5;
        padding-top: $space/2.5;

        @include media-breakpoint-down(md) {
            margin-top: $space/4;
            padding-top: $space/4;
            padding-bottom: $space/4;
            flex-direction: column;
        }

        border-top: 1px solid rgba($color: $black, $alpha: 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_info {
            margin-bottom: 1rem;
        }
    }
}


.social {
    &__icons {
        display: flex;

        li {
            margin-right: 15px;

            a {
                color: $black;
                font-size: 16px;

                &:hover {
                    color: $blue;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {

    .mobile__menu {
        &_btn {
            position: relative;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            width: 14px;
            height: 100%;
            height: 2px;
            border: none;
            background: none;
            transition: transform .4s ease-in-out;

            &:before,
            &:after {
                position: absolute;
                display: inline-block;
                background: $blue;
                content: "";
            }

            /* Vertical line */
            &:before {
                width: 14px;
                height: 2px;
                opacity: 1;
                transition: opacity .3s ease-in-out;
            }

            /* horizontal line */
            &:after {
                width: 2px;
                height: 14px;

            }
        }

        &.active {
            .mobile__menu_btn {
                transform: rotate(90deg);

                &:before {
                    opacity: 0;
                }


            }
        }
    }
}


.scrollTop {
    position: fixed;
    right: 35px;
    bottom: 10px;
    background-color: $black;
    padding: 10px;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;

    a {
        background-image: url('../images/icon_up.svg');
        text-indent: -9999px;
        display: block;
        height: 20px;
        width: 20px;
        background-size: 20px;
        background-repeat: no-repeat;
    }
}