.car-pic {
    width: 100%;
    height: 250px;
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
    transition: .1s linear;
}

.car-colors {
    .car {
        &-white {
            background-color: $car-white;
            color: $car-white;
        }

        &-red {
            background-color: $car-red;
            color: $car-red;
        }

        &-blue {
            background-color: $car-blue;
            color: $car-blue;
        }

        &-grey {
            background-color: $car-grey;
            color: $car-grey;
        }

        &-brown {
            background-color: $car-brown;
            color: $car-brown;
        }

        &-black {
            background-color: $car-black;
            color: $car-black;
        }

        &-purple {
            background-color: $car-purple;
            color: $car-purple;
        }

    }

    display: flex;
    align-items: center;
    justify-content: center;

    span {
        cursor: pointer;
        margin: 0 8px;
        width: 20px;
        height: 20px;
        text-indent: -9999px;
        background-color: #03448d;
        color: #03448d;
        position: relative;
        border-radius: 50%;
        border: none;
        outline: none;

        &.active {
            &::before {
                content: "";
                width: 32px;
                height: 32px;
                border: 1px solid;
                position: absolute;
                display: block;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                border-radius: 50%;
            }
        }
    }
}

.car-details {
    .car-name {
        font-size: 20px;
        font-family: $semibold;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 0.5rem;

        small {
            color: lighten($color: $black, $amount: 50%);
        }
    }

    .car-tag {
        font-size: 16px;
        color: lighten($color: $black, $amount: 50%);
    }

    &__spec {
        margin-top: 1.5rem;

        &_head {
            font-family: $semibold;
            cursor: pointer;
            position: relative;
        }


    }
}

.cardetails-accordion {
    &.compare-car {
        .cardetails {
            table {
                margin-bottom: 0;

                td {
                    width: 25%;
                    font-size: 12px;
                    text-align: center;

                    &:first-child {
                        text-align: left;
                    }
                }
            }
        }
    }

    .cardetails {
        display: none;
        padding: 1rem 0;

        &.active {
            display: block;
        }

        table {
            margin-bottom: 0;

            td {
                width: 50%;
                font-size: 12px;
            }
        }

        &__block {
            padding: 0.75rem 0;
            border-top: 1px solid darken($color: $grey, $amount: 5%);

            &:last-child {
                border-bottom: 1px solid darken($color: $grey, $amount: 5%);
            }
        }


    }
}

.acc-arrow {
    display: inline-block;

    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;

    &::after,
    &::before {
        transition: transform 0.25s ease;
        display: block;
        position: absolute;
        top: 50%;
        width: 10px;
        height: 2px;
        background-color: currentColor;
        content: '';
    }

    &::before {
        left: 4px;
        transform: rotate(45deg);
    }

    &:after {
        right: 4px;
        transform: rotate(-45deg);
    }
}

.active {
    .acc-arrow {
        &::before {
            left: 4px;
            transform: rotate(-45deg);
        }

        &:after {
            right: 4px;
            transform: rotate(45deg);
        }
    }
}

input[type=range]+.thumb {
    display: none;
}

.buy-online {

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        background-color: $black;
    }

    .nav-pills .nav-link {
        background-color: $grey;
        border-radius: 0;
        padding: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-family: $semibold;
        letter-spacing: 1.5px;
        height: $btn-h;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.loan__actions {
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;

        li {
            text-align: center;
            padding: 0 10px;


            a {
                display: block;
                box-shadow: none;
                border-radius: 0;
                height: $btn-h;
                padding: 0 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $black;
                border: none;
                font-size: 12px;
                font-family: $semibold;
                letter-spacing: 1.5px;

                &:hover {
                    background: $black;
                    color: rgba($color: #fff, $alpha: 0.5);
                    box-shadow: none;
                    border: none;
                }
            }
        }
    }
}

.deposit {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    font-family: $semibold;
    letter-spacing: 1.5px;
}

.reserve-stepper {
    ul.stepper.horizontal::before {
        box-shadow: none;
    }

    ul.stepper.horizontal .step .step-title::before {
        left: 0;
    }

    ul.stepper.horizontal .step .step-title {
        padding: 0 25px 0 40px;
        font-size: 12px;
        letter-spacing: 1px;
        font-family: $semibold;
    }

    ul.stepper.horizontal .step.active:not(:last-of-type)::after,
    ul.stepper.horizontal .step:not(:last-of-type)::after {
        width: 50px;
    }

    ul.stepper.horizontal .step .step-content {
        padding: 0;
    }
}

.payamount {
    background: $grey;
    padding: 0.75rem;
    text-align: center;

    &.car-details {
        .car-name {
            margin-bottom: 0;
        }
    }
}

.exploremore {
    a {
        overflow: hidden;

        &:hover {
            .exploremore__car {
                img {
                    transform: scale(1.02);
                }
            }
        }
    }

    &__car {
        img {
            max-width: 400px;
            width: 100%;
            transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
        }
    }

    &__info {
        h4 {
            font-size: 14px;
            text-align: center;
            margin-top: 15px;
            margin-bottom: 0;
            text-transform: uppercase;
            font-family: $semibold;

            span {
                color: rgba($color: $black, $alpha: 0.8);
                font-family: $medium;
            }
        }
    }
}

.login {
    max-width: 370px;
    margin: 0 auto;
}