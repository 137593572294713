.exterior__360 {
    &_img {
        img {
            max-width: 100%;
            margin: 0 auto;
        }
    }

    &_action {
        button {
            margin: 0 5px;
            width: 20px;
            height: 20px;
            text-indent: -9999px;
            background-color: $blue;
            color: $blue;
            position: relative;
            border-radius: 50%;
            border: none;
            outline: none;

            &.current {
                &::before {
                    content: "";
                    width: 32px;
                    height: 32px;
                    border: 1px solid;
                    position: absolute;
                    display: block;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                    border-radius: 50%;
                }
            }

            &.color {
                &-red {
                    background-color: $car-red;

                    &.current {
                        &::before {
                            color: $car-red;
                        }
                    }
                }

                &-white {
                    background-color: $car-white;

                    &.current {
                        &::before {
                            color: $car-white;
                        }
                    }
                }

                &-blue {
                    background-color: $car-blue;

                    &.current {
                        &::before {
                            color: $car-blue;
                        }
                    }
                }

                &-grey {
                    background-color: $car-grey;

                    &.current {
                        &::before {
                            color: $car-grey;
                        }
                    }
                }

                &-brown {
                    background-color: $car-brown;

                    &.current {
                        &::before {
                            color: $car-brown;
                        }
                    }
                }

                &-black {
                    background-color: $car-black;

                    &.current {
                        &::before {
                            color: $car-black;
                        }
                    }
                }
            }
        }
    }


    .reel-preloader {
        background: $blue !important;
        height: 1px !important;
        bottom: 20px !important;
    }

    .reel-indicator.x {

        display: none;
    }
}