.c-header {
    padding: 5px 0;

    &__navbar {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        &-brand {
            &-wrapper {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
            }

            &-default {
                max-width: 230px;

                @include media-breakpoint-down(md) {
                    max-width: 200px;
                }
            }
        }

        &-nav {
            align-items: center;

            .nav-item {
                padding: 0.5rem 1rem;

                a {
                    color: $black;
                    text-transform: uppercase;
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    font-family: $medium;
                    padding: 0;

                    &.active {

                        color: $blue;

                    }
                }
            }
        }
    }
}

.findlocation {
    &__wrapper {
        display: flex;
        align-items: center;
        color: $black;

        &_icon {
            margin-left: 15px;
            width: 40px;
            height: 40px;
            background-color: $black;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                stroke: #fff;

                &.dark {
                    stroke: $black;
                }
            }
        }

        &_text {
            font-size: 12px;
            text-transform: uppercase;
            font-family: $semibold;
        }

    }

}

.navbar-collapse {
    position: relative;
}

.dropdown-menu {
    top: 54px;
    border: none;
    border-radius: 0;
    padding: 0 1rem 1rem;
}

.has-mega-menu {
    .dropdown-menu {
        width: 100%;

        &::before,
        &:after {
            content: "";
            position: absolute;
            background: #fff;
            width: 100%;
            top: 0;
            bottom: 0;
            z-index: 0;
        }

        &::before {
            left: -100%;
        }

        &:after {
            right: -100%;
        }
    }
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.c-header__navbar {
    .navbar-brand {
        padding: 0;
    }

    nav {
        background-color: #fff;
        height: auto;
        line-height: normal;
        box-shadow: none;
        padding: 0;

        ul {
            &.nav {
                margin-left: auto;
                margin-right: auto;
            }

            li {
                a {
                    color: $black;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-family: $medium;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
}

.navbar-collapse {
    background-color: #fff;
    z-index: 99;
}


.c-header__navbar-findlocation {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.theme-breadcrumb {
    background: rgba(0, 0, 0, 0.04) url('../images/contact.jpg') top left repeat;
    height: 360px;
    background-size: cover;
    height: 360px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;

    &::before {
        content: "";
        background-color: rgba(4, 1, 24, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    &__area {
        letter-spacing: 1px;
        position: absolute;
        top: 50%;
        right: 0;
        opacity: 0.9;
        left: 0;

        h1 {
            margin: 0 0 10px;
            font-weight: 700;
            color: #fff;
            font-size: 30px;
            text-transform: uppercase;
            font-family: $semibold;
        }

        h4 {
            font-family: $semibold;
            color: #fff;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 2.5px;
        }
    }
}

.bg-breadcrumb {
    position: relative;
    text-align: center;
    background-color: $blue;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    h1 {
        font-family: $semibold;
        color: #fff;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        margin-bottom: 0;
    }
}

.breadcrumb-section {
    position: relative;

    .breadcrumb-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;

        h1 {
            font-family: $semibold;
            color: $black;
            font-size: 24px;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            margin-bottom: 0;
        }
    }
}